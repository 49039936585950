import { useInfiniteQuery } from '@tanstack/vue-query';
import { paginatedApiRequest } from '@glow/shared/lib/http';
import { notificationKeys } from './keys';
import { type Notification } from '../types';
import { tryGetNextPage } from '@glow/shared/lib/vueQuery';

export function getNotifications(page: number) {
  return paginatedApiRequest<Notification[]>('get', '/user/notifications', {
    page,
  });
}

export function useGetNotificationsInfinite(enabled: MaybeRef<boolean> = true) {
  return useInfiniteQuery(
    notificationKeys.list(),
    ({ pageParam }) => getNotifications(pageParam),
    {
      getNextPageParam: (lastPage) => tryGetNextPage(lastPage.meta!),
      enabled,
    }
  );
}
