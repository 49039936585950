<script setup lang="ts">
import {
  type NotificationsScreen,
  IndexScreen,
  DetailsScreen,
} from './screens';
import { SlideTransition } from '@glow/shared/components/transitions';
import { type Notification } from '../types';
import { useReadNotification } from '../api/readNotification';

const currentScreen = ref<NotificationsScreen>('index');
const slideDirection = ref<'left' | 'right'>('left');
const selectedNotification = ref<Notification>();
const { mutate: readNotification } = useReadNotification();

async function goBack() {
  selectedNotification.value = undefined;
  slideDirection.value = 'right';
  await nextTick();
  currentScreen.value = 'index';
}
async function goToScreen(screen: NotificationsScreen) {
  slideDirection.value = 'left';
  await nextTick();
  currentScreen.value = screen;
}
function selectNotification(notification: Notification) {
  if (!notification.isRead) readNotification(notification.id);
  selectedNotification.value = notification;
  goToScreen('details');
}

provide<() => void>('goBack', goBack);
provide<(screen: NotificationsScreen) => void>('goToScreen', goToScreen);
</script>

<template>
  <div>
    <SlideTransition :direction="slideDirection">
      <IndexScreen
        v-if="currentScreen === 'index'"
        @select-notification="selectNotification"
      />
      <DetailsScreen
        v-else-if="currentScreen === 'details' && selectedNotification"
        :notification="selectedNotification"
      />
    </SlideTransition>
  </div>
</template>
