import { apiRequest } from '@glow/shared/lib/http';
import { useInvalidatingMutation } from '@glow/shared/lib/vueQuery';
import { notificationKeys } from './keys';

export function readAllNotifications() {
  return apiRequest('post', '/user/notifications/read/all');
}

export function useReadAllNotifications() {
  return useInvalidatingMutation(notificationKeys.all, readAllNotifications);
}
