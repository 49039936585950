import { apiRequest } from '@glow/shared/lib/http';
import { useInvalidatingMutation } from '@glow/shared/lib/vueQuery';
import { notificationKeys } from './keys';

export function readNotification(id: string) {
  return apiRequest('post', `/user/notifications/read/${id}`);
}

export function useReadNotification() {
  return useInvalidatingMutation(notificationKeys.all, readNotification);
}
